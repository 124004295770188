import React from 'react';

function WeddingInfo() {
    return (
        <div id="wedding-info-element" className="px-6 max-w-[492px] w-full mx-auto mt-4">
            <h2 className="emitha-script flex items-center justify-center text-7xl mb-4">Our Wedding</h2>
            <p className="text-lg text-gray-600 text-center">
                We are excited to celebrate our special day with you! Here are the details of our wedding.
            </p>
            <div className="flex flex-col items-center mt-2">
                <div className="flex flex-col mt-4">
                    <h3 className="text-gray-900 text-xl text-center font-medium">Date</h3>
                    <p className="text-gray-700 text-lg">December 21, 2024</p>
                </div>
                <div className="flex flex-col mt-4">
                    <h3 className="text-gray-900 text-xl text-center font-medium">Location</h3>
                    <p className="text-gray-700 text-lg text-center">
                        Church of Grace<br />
                        221 Hardie Ave NW<br />
                        Renton, WA 98057
                    </p>
                </div>
                <div className="flex flex-col mb-4 mt-6">
                    <p className="text-gray-700 text-lg text-center">
                        We can't wait to share this beautiful occasion with all of our loved ones. Thank you for being a part of our journey.
                    </p>
                </div>
            </div>
            <div className="flex justify-center mt-6 mb-4">
                <a href="https://www.crateandbarrel.com/gift-registry/kashubskiy-alexa/r7181624" className="btn border-4 border-white px-3 py-2 text-xl text-gray-600">
                    Crate & Barrel Registry
                </a>
            </div>
        </div>
    );
}

export default WeddingInfo;
