import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import WeddingRedirect from './components/not_found/WeddingRedirect';
import RSVP from './components/rsvp/RSVP';
import WeddingInfo from './components/wedding/WeddingInfo';


function App() {

    console.log(window.innerHeight);

    return (
        <HashRouter>
            <div className={`flex items-center justify-center min-h-[${window.innerHeight}px]`}>
                <div className="overlay max-w-4xl p-8 rounded-xl shadow-lg overflow-hidden scroll-smooth snap-always m-8 min-w-[320px] w-full flex flex-col justify-center max-w-[548px]">
                    <Routes>
                        <Route exact path="/" element={<RSVP />} />
                        <Route path="/wedding" element={<WeddingInfo />} />
                        <Route path="*" element={<WeddingRedirect />} />
                    </Routes>
                </div>
            </div>
        </HashRouter>
    );
}

export default App;
