import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LandingContent from './LandingContent';
import RSVPForm from './RSVPForm';
import SuccessMessage from './SuccessMessage';

function RSVP() {
    const [isRSVPFormVisible, setRSVPFormVisible] = useState(false);
    const [isSuccessMessageVisible, setSuccessMessageVisible] = useState(false);
    const [attendingChecked, setAttendingChecked] = useState(false);
    const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
    const [event, setEvent] = useState(null);

    const [rsvped, setRSVPed] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkCookie = () => {
            const cookies = document.cookie.split(';');
            for (let cookie of cookies) {
                const [name, value] = cookie.trim().split('=');
                if (name === 'submitted' && value === 'true') {
                    setRSVPed(true);
                    break;
                }
            }
        };

        checkCookie();
    }, [navigate]);

    const handleRSVPClick = (e) => {
        e.preventDefault();
        setRSVPFormVisible(true);
    };

    const handleAttendingCheckboxChange = (e) => {
        setAttendingChecked(e.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!attendingChecked) {
            setEvent(e);
            setConfirmModalVisible(true);
            return;
        }
        submitRSVP(e);
    };

    const handleConfirmNoAttendance = (confirm) => {
        setConfirmModalVisible(false);
        if (confirm) {
            submitRSVP(event);
        }
    };

    const submitRSVP = (e) => {
        const name = e.target.name.value;
        const email = e.target.email.value;
        const guestsAttending = attendingChecked ? e.target.attending.value : null;
        const message = e.target.message.value;

        const data = {
            name,
            email,
            guestsAttending,
            message
        };

        fetch('https://rsvp-api.yarmoshik.com/rsvp', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    alert(data.error);
                } else {
                    document.cookie = "submitted=true; path=/";
                    setRSVPFormVisible(false);
                    setSuccessMessageVisible(true);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('There was an error submitting your RSVP. Please contact us directly (253) 886-9063.');
            });
    };

    return (
        <>
            {!isRSVPFormVisible && !isSuccessMessageVisible && !rsvped && (
                <LandingContent handleRSVPClick={handleRSVPClick} />
            )}
            {isRSVPFormVisible && !rsvped && (
                <RSVPForm
                    handleSubmit={handleSubmit}
                    attendingChecked={attendingChecked}
                    handleAttendingCheckboxChange={handleAttendingCheckboxChange}
                />
            )}
            {(isSuccessMessageVisible || rsvped) && <SuccessMessage attendingChecked={attendingChecked} rsvped={rsvped} />}
            {isConfirmModalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <h2 className='font-semibold'>Confirm Not Attending</h2>
                        <p>Are you sure you won't be attending?</p>
                        <button className="btn border-4 border-gray-200 px-2 py-1 text-xl text-gray-600 mt-2" onClick={() => handleConfirmNoAttendance(true)}>Yes</button>
                        <button className="ml-4 btn border-4 border-gray-200 px-2 py-1 text-xl text-gray-600" onClick={() => handleConfirmNoAttendance(false)}>Cancel</button>
                    </div>
                </div>
            )}
        </>
    );
}

export default RSVP;