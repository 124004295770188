import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SuccessMessage({ attendingChecked, rsvped }) {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    navigate('/wedding');
                    clearInterval(timer);
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(timer); // Cleanup the timer on component unmount
    }, [navigate]);

    const handleRedirectClick = () => {
        navigate('/wedding');
    };

    return (
        <div id="success-message" className="flex flex-col justify-center items-center h-full">
            <h2 className="emitha-script text-7xl text-center text-gray-800 mb-6 mt-1">Thanks!</h2>
            <p className="text-xl text-center text-gray-600 mb-8 mx-8">
                {!rsvped ? "You will receive an email confirmation shortly." : "Please check your email for the confirmation."}
            </p>
            <div className="text-center">
                {attendingChecked ? <>
                    <p className="text-xl text-gray-600">Date: December 21, 2024</p>
                    <p className="text-xl text-gray-600">Location: Church of Grace</p>
                </> :
                    !rsvped ? <p className="text-xl text-gray-600">We're sorry you can't make it, but we appreciate you letting us know and hope to celebrate together in the future!</p> : null
                }
            </div>
            <div className="text-center mt-4">
                <button
                    className="btn border-4 border-white px-3 py-2 text-xl text-gray-600 my-2"
                    onClick={handleRedirectClick}
                >
                    Wedding Registry & More ({countdown} {countdown === 1 ? 'second' : 'seconds'})
                </button>
            </div>
        </div>
    );
}

export default SuccessMessage;