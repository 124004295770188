import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function WeddingRedirect() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/wedding');
    }, [navigate]);

    return null;
}

export default WeddingRedirect;
