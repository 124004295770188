import React from "react";

function LandingContent({ handleRSVPClick }) {
    return (
        <div id="landing-content">
            <h1 className="emitha-script text-7xl text-center text-gray-800 mb-6 mt-1">You're Invited!</h1>
            <p className="text-xl text-center text-gray-600 mb-8 mx-8">
                Join us for our special day as we say "I do" and celebrate our love.
            </p>

            <div className="text-center">
                <h2 className="emitha-script text-7xl text-gray-800">Michael & Alexa</h2>
                <p className="text-xl text-gray-600">Date: December 21, 2024</p>
                <p className="text-xl text-gray-600">Location: Church of Grace</p>
            </div>
            <div className="text-center">
                <button
                    id="rsvp-button"
                    onClick={handleRSVPClick}
                    className="btn border-4 border-white px-3 py-2 mt-8 text-xl text-gray-600"
                >
                    RSVP
                </button>
            </div>
        </div>
    );
}

export default LandingContent;
