import React from 'react';

function RSVPForm({ handleSubmit, attendingChecked, handleAttendingCheckboxChange }) {
    return (
        <form id="rsvp-form-element" className="px-6 max-w-[492px] w-full" onSubmit={handleSubmit}>
            <h2 className="emitha-script flex items-center justify-center mt-4 text-7xl mb-2">RSVP</h2>
            <p className="text-lg text-gray-600 text-center">
                Thank you for your RSVP! Please answer the questions below.
            </p>
            <div className="flex flex-col mt-4">
                <label htmlFor="name" className="text-gray-900 text-xl">
                    Name
                </label>
                <input type="text" id="name" name="name" className="p-2 rounded-lg" required />
            </div>
            <div className="flex flex-col mt-4">
                <label htmlFor="email" className="text-gray-900 text-xl">
                    Email
                </label>
                <input type="email" id="email" name="email" className="p-2 rounded-lg" required />
            </div>
            <div className="flex items-center mt-4 justify-center">
                <input
                    type="checkbox"
                    id="attending-checkbox"
                    className="form-checkbox h-6 w-6 text-gray-700 ml-2"
                    checked={attendingChecked}
                    onChange={handleAttendingCheckboxChange}
                />
                <label htmlFor="attending-checkbox" className="ml-3 text-gray-900 text-xl">
                    Will you be attending?
                </label>
            </div>
            {attendingChecked && (
                <div id="guests-attending" className="flex flex-col mt-4">
                    <label htmlFor="attending" className="text-gray-900 text-xl">
                        Total Guests Attending
                    </label>
                    <input
                        type="number"
                        id="attending"
                        name="attending"
                        className="p-2 rounded-lg"
                        required
                        min="1"
                        max="15"
                        step="1"
                    />
                </div>
            )}
            <div className="flex flex-col mt-4">
                <label htmlFor="message" className="text-gray-900 text-xl">
                    Message to the Bride & Groom
                </label>
                <textarea
                    id="message"
                    name="message"
                    className="p-2 rounded-lg"
                    rows="1"
                />
            </div>
            <div className="flex justify-center mt-8">
                <button type="submit" className="btn border-4 border-white px-3 py-2 text-xl text-gray-600">
                    Send
                </button>
            </div>
        </form>
    );
}

export default RSVPForm;